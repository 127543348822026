<template>
  <v-container fluid>
    <v-row>
      <v-col cols="3"></v-col>
      <v-col cols="3"></v-col>
      <v-col cols="3"></v-col>
      <v-col cols="3">
        <v-btn
          sm="12"
          md="3"
          lg="3"
          xl="3"
          @click="viewType"
          depressed
          elevation="2"
          color="primary"
          class="float-right"
        >
          <v-icon left>
            mdi-map
          </v-icon>
          <h6>
            MAP VIEW
          </h6>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-card class="cursor-hover" @click="getAllVehicles()">
          <v-card-text>
            <span class="subtitle font-weight-bold black--text"
              >Total Vehicles</span
            >
            <v-divider></v-divider>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-icon color="red" x-large>mdi-truck</v-icon>
                </v-col>
                <v-col cols="6">
                  <span class="display-1 font-weight-bold black--text">{{
                    /* countObjects.no_of_vehicles */
                    /*  Object.entries(countObjects).length != 0
                      ? countObjects.no_of_vehicles
                      : 0 */

                    Object.entries($store.state.dashboard_boxes_counts)
                      .length != 0
                      ? $store.state.dashboard_boxes_counts.total_vehicles_count
                      : 0
                  }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="2">
        <v-card class="cursor-hover" @click="getDispatchedVehicles()">
          <v-card-text>
            <span class="subtitle font-weight-bold black--text"
              >Dispatched</span
            >
            <v-divider></v-divider>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-icon color="blue" x-large>mdi-truck</v-icon>
                </v-col>
                <v-col cols="6">
                  <span class="display-1 font-weight-bold black--text">{{
                    Object.entries($store.state.dashboard_boxes_counts)
                      .length != 0
                      ? $store.state.dashboard_boxes_counts
                          .dispatched_vehicles_count
                      : 0
                  }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="2">
        <v-card class="cursor-hover" @click="getRunningVehicles()">
          <v-card-text>
            <span class="subtitle font-weight-bold black--text">Running</span>
            <v-divider></v-divider>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-icon color="#008b0d" x-large>mdi-truck</v-icon>
                </v-col>
                <v-col cols="6">
                  <span class="display-1 font-weight-bold black--text">{{
                    Object.entries($store.state.dashboard_boxes_counts)
                      .length != 0
                      ? $store.state.dashboard_boxes_counts
                          .running_vehicles_count
                      : 0
                  }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="2">
        <v-card class="cursor-hover" @click="getStoppedVehicles()">
          <v-card-text>
            <span class="subtitle font-weight-bold black--text">Stopped</span>
            <v-divider></v-divider>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-icon color="#940000" x-large>mdi-truck</v-icon>
                </v-col>
                <v-col cols="6">
                  <span class="display-1 font-weight-bold black--text">{{
                    Object.entries($store.state.dashboard_boxes_counts)
                      .length != 0
                      ? $store.state.dashboard_boxes_counts
                          .stopped_vehicles_count
                      : 0
                  }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="2">
        <v-card class="cursor-hover" @click="getEngineOnVehicles()">
          <v-card-text>
            <span class="subtitle font-weight-bold black--text">Engine On</span>
            <v-divider></v-divider>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-icon color="#009688" x-large>mdi-truck</v-icon>
                </v-col>
                <v-col cols="6">
                  <!-- <span class="display-1 font-weight-bold black--text">{{
                    Object.entries($store.state.dashboard_boxes_counts)
                      .length != 0
                      ? $store.state.dashboard_boxes_counts
                          .running_vehicles_count +
                        $store.state.dashboard_boxes_counts
                          .stopped_vehicles_count
                      : 0
                  }}</span> -->
                  <span class="display-1 font-weight-bold black--text">{{
                    Object.entries($store.state.dashboard_boxes_counts)
                      .length != 0
                      ? $store.state.dashboard_boxes_counts
                          .engine_on_vehicles_count
                      : 0
                  }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card> </v-col
      ><v-col cols="2">
        <v-card class="cursor-hover" @click="getEngineOffVehicles()">
          <v-card-text>
            <span class="subtitle font-weight-bold black--text"
              >Engine Off</span
            >
            <v-divider></v-divider>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-icon color="#000000" x-large>mdi-truck</v-icon>
                </v-col>
                <v-col cols="6">
                  <!-- <span class="display-1 font-weight-bold black--text">{{
                    Object.entries($store.state.dashboard_boxes_counts)
                      .length != 0 && Object.entries(countObjects).length != 0
                      ? countObjects.no_of_vehicles -
                        ($store.state.dashboard_boxes_counts
                          .running_vehicles_count +
                          $store.state.dashboard_boxes_counts
                            .stopped_vehicles_count)
                      : 0
                  }}</span> -->
                  <span class="display-1 font-weight-bold black--text">{{
                    Object.entries($store.state.dashboard_boxes_counts)
                      .length != 0
                      ? $store.state.dashboard_boxes_counts
                          .engine_off_vehicles_count
                      : 0
                  }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="4">
        <v-card class="cursor-hover" @click="getDelayVehicles()">
          <v-card-text>
            <span class="subtitle font-weight-bold black--text">Delay</span>
            <v-divider></v-divider>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-icon x-large color="#FF9800">mdi-crosshairs-gps</v-icon>
                </v-col>
                <v-col cols="6">
                  <span class="display-1 font-weight-bold black--text">{{
                    Object.entries($store.state.dashboard_boxes_counts)
                      .length != 0
                      ? $store.state.dashboard_boxes_counts.delay_vehicles_count
                      : 0
                  }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="cursor-hover" @click="getGPSForServiceVehicles()">
          <v-card-text>
            <span class="subtitle font-weight-bold black--text"
              >GPS For Service</span
            >
            <v-divider></v-divider>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-icon x-large>mdi-crosshairs-gps</v-icon>
                </v-col>
                <v-col cols="6">
                  <span class="display-1 font-weight-bold black--text">{{
                    Object.entries($store.state.dashboard_boxes_counts)
                      .length != 0
                      ? $store.state.dashboard_boxes_counts
                          .gps_for_service_vehicles_count
                      : 0
                  }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="cursor-hover" @click="getDecommissionedVehicles()">
          <v-card-text>
            <span class="subtitle font-weight-bold black--text"
              >Decommissioned</span
            >
            <v-divider></v-divider>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-icon x-large color="#E91E63">mdi-crosshairs-gps</v-icon>
                </v-col>
                <v-col cols="6">
                  <span class="display-1 font-weight-bold black--text">{{
                    Object.entries($store.state.dashboard_boxes_counts)
                      .length != 0
                      ? $store.state.dashboard_boxes_counts
                          .decommissioned_vehicles_count
                      : 0
                  }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-divider></v-divider>
        <v-card class="elevation-0">
          <v-container fluid class="pt-5">
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                <v-row>
                  <v-col cols="12" class="py-2">
                    <v-select
                      v-model="filterSubCompany"
                      :items="filterSubCompanyItems"
                      label="Fitler By Sub-Company"
                      dense
                      clearable
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                offset-md="4"
                lg="6"
                offset-lg="4"
                xl="6"
                offset-xl="4"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="4"
                    offset-md="5"
                    lg="4"
                    offset-lg="5"
                    xl="4"
                    offset-xl="5"
                    class="py-2"
                  >
                    <search-field
                      @search="search"
                      :title="'Search'"
                    ></search-field>
                  </v-col>
                  <v-col
                    v-if="showPagination"
                    cols="12"
                    sm="12"
                    md="3"
                    lg="3"
                    xl="3"
                    class="py-2"
                  >
                    <div
                      class="float-right d-none d-sm-flex d-sm-none d-md-flex"
                    >
                      <p
                        class="text-xs-center grey--text caption my-2 mx-1"
                        style="font-size: 14px;display: inline-block;"
                      >
                        {{ itemFrom + " - " + itemTo + " of " }}
                        {{ totalItems }}
                      </p>
                      <v-btn
                        style="display: inline-block; height: 34px;"
                        fab
                        light
                        small
                        text
                        @click="backClick"
                        :disabled="disabledBack"
                      >
                        <v-icon dark>mdi-chevron-left</v-icon>
                      </v-btn>
                      <v-btn
                        style="display: inline-block; height: 34px;"
                        fab
                        light
                        small
                        text
                        @click="nextClick"
                        :disabled="disabledNext"
                      >
                        <v-icon dark>mdi-chevron-right</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="theData"
          class="elevation-1"
          hide-default-footer
          dense
          :server-items-length="$store.state.vehicle_array.length"
        >
          <template
            v-if="$store.state.vehicle_array.length === 0"
            v-slot:no-data
          >
            <v-container>
              <v-row>
                <v-col md="6" offset-md="3">
                  <span class="title font-weight-bold">NO DATA AVAILABLE.</span>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-else v-slot:body="{ items }">
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td class="text-center" style="font-size: 10px;">
                  {{ index + offset + 1 }}
                </td>
                <td
                  class="text-uppercase text-no-wrap"
                  style="font-size: 10px;"
                >
                  {{ item.plate_no }}
                </td>
                <td
                  class="text-uppercase text-no-wrap"
                  style="font-size: 10px;"
                >
                  {{ item.sub_company ? item.sub_company.name : "--" }}
                </td>
                <td
                  class="text-uppercase text-no-wrap"
                  style="font-size: 10px;"
                >
                  {{ item.imei }}
                </td>
                <td
                  v-if="$store.state.employee.enable_mark_logo"
                  class="text-uppercase text-no-wrap"
                  style="font-size: 10px;"
                >
                  {{ item.sim_card_no }}
                </td>
                <td class="text-center text-no-wrap" style="font-size: 10px;">
                  {{ $dateFormat(item.datetimestamp, "YYYY-MM-DD HH:mm:ss") }}
                </td>
                <td
                  class="viewCurrentLocation"
                  style="font-size: 10px;"
                  @click="viewCurrentLocation(item.plate_no, item)"
                >
                  {{ item.location }}
                </td>
                <td class="text-no-wrap text-center" style="font-size: 10px;">
                  {{ item.temperature + " °C" }}
                </td>
                <td class="text-no-wrap text-center" style="font-size: 10px;">
                  {{ item.voltage + " %" }}
                </td>
                <td class="text-no-wrap text-center" style="font-size: 10px;">
                  {{ item.fuel + " %" }}
                </td>
                <td class="text-no-wrap text-center" style="font-size: 10px;">
                  {{ item.vehicle_status === 1 ? "ON" : "OFF" }}
                </td>
                <td class="text-no-wrap text-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        :color="item.status.color"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{
                          item.status.status === 2 ||
                          item.status.status === 6 ||
                          item.status.status === 9 ||
                          item.status.status === 8
                            ? "mdi-truck"
                            : "mdi-crosshairs-gps"
                        }}
                      </v-icon>
                    </template>
                    <span>{{ item.status.code }}</span>
                  </v-tooltip>
                </td>
                <td class="text-no-wrap text-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="light-red"
                        icon
                        :disabled="
                          item.current_alerts.length != 0 ? false : true
                        "
                        @click="viewCurrentAlarms(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-bell</v-icon>
                      </v-btn>
                    </template>
                    <span>View Current Alerts</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!---- Here is the View Current Location Dialog ------>

    <mini-dialog
      :dialog="viewCurrentLocationDialog"
      :max-width="'1000px'"
      :cardActions="false"
    >
      <v-card-title class="pl-3 gradientColor">
        <span class="headline font-weight-black white--text">{{
          `Current Location : ${this.plateData}`
        }}</span>
        <v-spacer></v-spacer>
        <v-btn dark icon @click="closeViewCurrentLocationDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0" style="height: 500px;">
        <official-map ref="map"></official-map>
      </v-card-text>
    </mini-dialog>

    <!---- Here is the View Current Alert Dialog ------>

    <v-dialog
      v-model="viewCurrentAlertsDialog"
      scrollable
      max-width="500px"
      persistent
    >
      <v-card>
        <v-container v-if="currentAlertsLoading">
          <v-row>
            <v-col cols="4"></v-col>
            <v-col cols="4" style="padding: 25px 50px;">
              <v-progress-circular
                :width="3"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
            <v-col cols="4"></v-col>
          </v-row>
        </v-container>
        <v-card-text
          v-else-if="!currentAlertsLoading && currentAlertsItems.length !== 0"
          v-for="(item, index) in currentAlertsItems"
          :key="index"
          class="pt-5"
        >
          <v-alert type="error">
            {{
              item.alarm_type === 0
                ? "ENGINE ON/OFF ALERT"
                : item.alarm_type === 1
                ? "CIRCUIT CUT-OFF ALERT"
                : item.alarm_type === 2
                ? "PANIC BUTTON ALERT"
                : item.alarm_type === 3
                ? "OVERSPEEDING ALERT"
                : item.alarm_type === 4
                ? "IDLE ALERT"
                : item.alarm_type === 5
                ? "PITSTOP ALERT"
                : item.alarm_type === 6
                ? "GEOZONE ALERT"
                : item.alarm_type === 7
                ? "GEOFENCE ALERT"
                : item.alarm_type === 8
                ? "HOTSPOT ALERT"
                : ""
            }}</v-alert
          >
          <span class="font-weight-black">Company: </span>
          {{ $store.state.company.name.toUpperCase() }}
          <br />
          <span class="font-weight-black">Type: </span>
          {{
            item.alarm_type === 0 ||
            item.alarm_type === 3 ||
            item.alarm_type === 4 ||
            item.alarm_type === 5 ||
            item.alarm_type === 6 ||
            item.alarm_type === 7 ||
            item.alarm_type === 8
              ? "SAFETY"
              : item.alarm_type === 1 || item.alarm_type === 2
              ? "SECURITY"
              : ""
          }}
          <br />
          <span class="font-weight-black">Time: </span>
          {{ item.datetimestamp }}
          <br />
          <span class="font-weight-black">Location: </span>
          {{ item.location }}
        </v-card-text>
        <v-card-text v-else class="pt-5">
          <v-alert type="error"> No Available Alert.</v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeViewCurrentAlertsDialog()"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Services from "@/services/CompanyServices";

export default {
  data: () => {
    return {
      title: "Dashboard",
      queryParams: {},
      countObjects: {},
      typeOfView: "dashboard",
      headers: [
        {
          text: "#",
          sortable: false,
          align: "center",
        },
        { text: "Plate No", sortable: false },
        { text: "Sub Company", sortable: false },
        { text: "GPS Device", sortable: false },
        {
          text: "Last Seen",
          sortable: false,
          align: "center",
        },
        { text: "Location", sortable: false },
        {
          text: "Temperature",
          sortable: false,
          align: "center",
        },
        {
          text: "Car Battery",
          sortable: false,
          align: "center",
        },
        { text: "Fuel", sortable: false, align: "center" },
        {
          text: "Ignition",
          sortable: false,
          align: "center",
        },
        {
          text: "Status",
          sortable: false,
          align: "center",
        },
        { text: "Alerts", align: "center", sortable: false },
      ],
      viewTypeItems: [
        {
          text: "Dashboard",
          value: "dashboard",
        },
        {
          text: "Map View",
          value: "map-view",
        },
      ],
      runningVehicleCount: 0,
      delayVehicleCount: 0,
      gpsForServiceCount: 0,
      viewCurrentLocationDialog: false,
      viewCurrentAlertsDialog: false,
      currentAlertsQueryParams: { limit: 10, offset: 10 },
      currentAlertsHeaders: [
        {
          text: "#",
          sortable: false,
          align: "center",
        },
        { text: "Alert Type", sortable: false },
        { text: "Company", sortable: false },
        { text: "Type", sortable: false },
        { text: "Time", sortable: false, align: "center" },
        { text: "Location", sortable: false },
      ],
      currentAlertsItems: [],
      currentAlertsLoading: false,
      filterSubCompany: null,
      filterSubCompanyItems: [],
      pageNumber: 1,
      totalItems: 0,
      itemFrom: 0,
      itemTo: 0,
      offset: 0,
      disabledNext: false,
      disabledBack: true,
      showPagination: false,
      filterStatus: 0,
      searchItem: "",
      plateData: "",
    };
  },
  // created: async function() {
  //   if (this.$store.state.token !== null) {
  //     if (this.$store.state.employee.enable_mark_logo) {
  //       this.headers.splice(4, 0, { text: "Sim No.", sortable: false });
  //     }

  //     const getSubCompanies = await this.setSubCompanies();

  //     this.filterSubCompanyItems = await Promise.all(
  //       getSubCompanies.map((item) => {
  //         return {
  //           text: item.name,
  //           value: item.id,
  //         };
  //       })
  //     );

  //     await this.getData();

  //     await this.sockets.subscribe("currentTransaction", async (data) => {
  //       if (data.company_id == this.$store.state.company.id) {
  //         const vehicleData = data;

  //         let vehiclesArray = this.$store.state.vehicle_array;

  //         // this.totalItems = this.$store.state.vehicle_array.length;

  //         this.totalItems = data.total_vehicles;

  //         this.itemFrom = this.totalItems > 0 ? this.offset + 1 : 0;

  //         this.itemTo = this.offset + 10;

  //         if (this.totalItems <= this.itemTo) {
  //           this.itemTo = this.totalItems;
  //         }

  //         if (this.itemTo === this.totalItems) {
  //           this.disabledNext = true;
  //         } else {
  //           this.disabledNext = false;
  //         }

  //         if (this.itemFrom > 1) {
  //           this.disabledBack = false;
  //         } else {
  //           this.disabledBack = true;
  //         }

  //         const index = vehiclesArray.findIndex((item) => {
  //           return item.imei === vehicleData.imei;
  //         });

  //         if (index > -1) {
  //           vehiclesArray.splice(index, 1);
  //           vehiclesArray.push(vehicleData);
  //         } else {
  //           vehiclesArray.push(vehicleData);
  //         }

  //         let finalVehiclesArray = vehiclesArray.filter((item) => {
  //           return item.company_id == this.$store.state.company.id;
  //         });

  //         const returnedVehicleList = await Promise.all(
  //           finalVehiclesArray.map(async (item, i) => {
  //             return {
  //               index: i,
  //               sim_card_no: item.sim_card_no,
  //               company: item.company,
  //               company_id: item.company_id,
  //               sub_company: item.sub_company,
  //               sub_company_id: item.sub_company_id,
  //               datetimestamp: item.datetimestamp,
  //               dispatch_count: item.dispatch_count,
  //               imei: item.imei,
  //               lat: item.lat,
  //               lng: item.lng,
  //               location: item.location,
  //               plate_no: item.plate_no,
  //               speed: item.speed,
  //               temperature: item.temperature,
  //               fuel: item.fuel,
  //               voltage: item.voltage,
  //               vehicle_status: item.vehicle_status,
  //               status: item.status,
  //               current_alerts: item.current_alerts,
  //             };
  //           })
  //         );

  //         const trueVehiclesArray = returnedVehicleList.sort((b, a) => {
  //           // if (a.plate_no < b.plate_no) {
  //           //   return -1;
  //           // }
  //           // if (a.plate_no > b.plate_no) {
  //           //   return 1;
  //           // }
  //           // return 0;

  //           return new Date(a.datetimestamp) - new Date(b.datetimestamp);
  //         });

  //         this.$store.dispatch("dispatchCurrentTransaction", trueVehiclesArray);

  //         this.$store.dispatch(
  //           "dispatchBackUpCurrentTransaction",
  //           trueVehiclesArray
  //         );

  //         this.$store.dispatch("dispatchDashboardBoxesCounts", {
  //           total_vehicles_count: data.total_vehicles,
  //           // total_vehicles_count: this.$store.state.vehicle_array.length,
  //           dispatched_vehicles_count: this.$store.state.vehicle_array.filter(
  //             (item) => {
  //               return item.dispatch_count != 0;
  //             }
  //           ).length,
  //           running_vehicles_count: this.$store.state.vehicle_array.filter(
  //             (item) => {
  //               return item.status.status == 2;
  //             }
  //           ).length,
  //           stopped_vehicles_count: this.$store.state.vehicle_array.filter(
  //             (item) => {
  //               return item.status.status == 6;
  //             }
  //           ).length,
  //           engine_on_vehicles_count: this.$store.state.vehicle_array.filter(
  //             (item) => {
  //               return item.vehicle_status == 1;
  //             }
  //           ).length,
  //           engine_off_vehicles_count: this.$store.state.vehicle_array.filter(
  //             (item) => {
  //               return item.vehicle_status == 0;
  //             }
  //           ).length,
  //           delay_vehicles_count: this.$store.state.vehicle_array.filter(
  //             (item) => {
  //               return item.status.status == 3;
  //             }
  //           ).length,
  //           gps_for_service_vehicles_count: this.$store.state.vehicle_array.filter(
  //             (item) => {
  //               return item.status.status == 4;
  //             }
  //           ).length,
  //           decommissioned_vehicles_count: this.$store.state.vehicle_array.filter(
  //             (item) => {
  //               return item.status.status == 5;
  //             }
  //           ).length,
  //         });
  //       }
  //     });

  //     this.showPagination = true;

  //     this.interval = await setInterval(() => {
  //       this.getDataMap();
  //     }, 1000 * 60);
  //   } else {
  //     this.$router.push({
  //       path: "/",
  //     });

  //     this.$store.dispatch("clearStore");
  //   }
  // },
  created: async function() {
    if (this.$store.state.token !== null) {
      if (this.$store.state.employee.enable_mark_logo) {
        this.headers.splice(4, 0, { text: "Sim No.", sortable: false });
      }

      const getSubCompanies = await this.setSubCompanies();

      this.filterSubCompanyItems = await Promise.all(
        getSubCompanies.map((item) => {
          return {
            text: item.name,
            value: item.id,
          };
        })
      );

      this.$store.dispatch("startLoading");

      const data = await this.getDataMap();

      this.$store.dispatch("endLoading");

      this.totalItems = data.total_vehicles;

      this.itemFrom = this.totalItems > 0 ? this.offset + 1 : 0;

      this.itemTo = this.offset + 10;

      if (this.totalItems <= this.itemTo) {
        this.itemTo = this.totalItems;
      }

      if (this.itemTo === this.totalItems) {
        this.disabledNext = true;
      } else {
        this.disabledNext = false;
      }

      if (this.itemFrom > 1) {
        this.disabledBack = false;
      } else {
        this.disabledBack = true;
      }

      const filteredData = data.data.filter((item) => {
        return item;
      });

      let finalVehiclesArray = filteredData.filter((item) => {
        return item.company_id == this.$store.state.company.id;
      });

      const returnedVehicleList = await Promise.all(
        finalVehiclesArray.map(async (item, i) => {
          return {
            index: i,
            sim_card_no: item.sim_card_no,
            company: item.company,
            company_id: item.company_id,
            sub_company: item.sub_company,
            sub_company_id: item.sub_company_id,
            datetimestamp: item.datetimestamp,
            dispatch_count: item.dispatch_count,
            imei: item.imei,
            lat: item.lat,
            lng: item.lng,
            location: item.location,
            plate_no: item.plate_no,
            speed: item.speed,
            temperature: item.temperature,
            fuel: item.fuel,
            voltage: item.voltage,
            vehicle_status: item.vehicle_status,
            status: item.status,
            current_alerts: item.current_alerts,
          };
        })
      );

      const trueVehiclesArray = returnedVehicleList.sort((b, a) => {
        // if (a.plate_no < b.plate_no) {
        //   return -1;
        // }
        // if (a.plate_no > b.plate_no) {
        //   return 1;
        // }
        // return 0;

        return new Date(a.datetimestamp) - new Date(b.datetimestamp);
      });

      this.$store.dispatch("dispatchCurrentTransaction", trueVehiclesArray);

      this.$store.dispatch(
        "dispatchBackUpCurrentTransaction",
        trueVehiclesArray
      );

      this.$store.dispatch("dispatchDashboardBoxesCounts", {
        total_vehicles_count: data.total_vehicles,
        // total_vehicles_count: this.$store.state.vehicle_array.length,
        dispatched_vehicles_count: this.$store.state.vehicle_array.filter(
          (item) => {
            let count = 0;

            if (typeof item.dispatch_count === "object") {
              count = parseInt(item.dispatch_count.count);
            } else {
              count = parseInt(item.dispatch_count);
            }

            return count != 0;
          }
        ).length,
        running_vehicles_count: this.$store.state.vehicle_array.filter(
          (item) => {
            return item.status.status == 2 && item.vehicle_status == 1;
          }
        ).length,
        stopped_vehicles_count: this.$store.state.vehicle_array.filter(
          (item) => {
            return item.status.status == 6 && item.vehicle_status == 1;
          }
        ).length,
        engine_on_vehicles_count: this.$store.state.vehicle_array.filter(
          (item) => {
            return (
              (item.vehicle_status == 1 && item.status.status == 2) ||
              (item.vehicle_status == 1 && item.status.status == 6)
            );
          }
        ).length,
        engine_off_vehicles_count: this.$store.state.vehicle_array.filter(
          (item) => {
            return item.vehicle_status == 0 || item.status.status == 7;
          }
        ).length,
        delay_vehicles_count: this.$store.state.vehicle_array.filter((item) => {
          return item.status.status == 3;
        }).length,
        gps_for_service_vehicles_count: this.$store.state.vehicle_array.filter(
          (item) => {
            return item.status.status == 4;
          }
        ).length,
        decommissioned_vehicles_count: this.$store.state.vehicle_array.filter(
          (item) => {
            return item.status.status == 5;
          }
        ).length,
      });

      this.showPagination = true;

      this.interval = await setInterval(async () => {
        const data = await this.getDataMap();

        const filteredData = data.data.filter((item) => {
          return item;
        });

        let finalVehiclesArray = filteredData.filter((item) => {
          return item.company_id == this.$store.state.company.id;
        });

        const returnedVehicleList = await Promise.all(
          finalVehiclesArray.map(async (item, i) => {
            return {
              index: i,
              sim_card_no: item.sim_card_no,
              company: item.company,
              company_id: item.company_id,
              sub_company: item.sub_company,
              sub_company_id: item.sub_company_id,
              datetimestamp: item.datetimestamp,
              dispatch_count: item.dispatch_count,
              imei: item.imei,
              lat: item.lat,
              lng: item.lng,
              location: item.location,
              plate_no: item.plate_no,
              speed: item.speed,
              temperature: item.temperature,
              fuel: item.fuel,
              voltage: item.voltage,
              vehicle_status: item.vehicle_status,
              status: item.status,
              current_alerts: item.current_alerts,
            };
          })
        );

        const trueVehiclesArray = returnedVehicleList.sort((b, a) => {
          // if (a.plate_no < b.plate_no) {
          //   return -1;
          // }
          // if (a.plate_no > b.plate_no) {
          //   return 1;
          // }
          // return 0;

          return new Date(a.datetimestamp) - new Date(b.datetimestamp);
        });

        this.$store.dispatch("dispatchCurrentTransaction", trueVehiclesArray);

        this.$store.dispatch(
          "dispatchBackUpCurrentTransaction",
          trueVehiclesArray
        );

        this.$store.dispatch("dispatchDashboardBoxesCounts", {
          total_vehicles_count: data.total_vehicles,
          // total_vehicles_count: this.$store.state.vehicle_array.length,
          dispatched_vehicles_count: this.$store.state.vehicle_array.filter(
            (item) => {
              let count = 0;

              if (typeof item.dispatch_count === "object") {
                count = parseInt(item.dispatch_count.count);
              } else {
                count = parseInt(item.dispatch_count);
              }

              return count != 0;
            }
          ).length,
          running_vehicles_count: this.$store.state.vehicle_array.filter(
            (item) => {
              return item.status.status == 2 && item.vehicle_status == 1;
            }
          ).length,
          stopped_vehicles_count: this.$store.state.vehicle_array.filter(
            (item) => {
              return item.status.status == 6 && item.vehicle_status == 1;
            }
          ).length,
          engine_on_vehicles_count: this.$store.state.vehicle_array.filter(
            (item) => {
              return (
                (item.vehicle_status == 1 && item.status.status == 2) ||
                (item.vehicle_status == 1 && item.status.status == 6)
              );
            }
          ).length,
          engine_off_vehicles_count: this.$store.state.vehicle_array.filter(
            (item) => {
              return item.vehicle_status == 0 || item.status.status == 7;
            }
          ).length,
          delay_vehicles_count: this.$store.state.vehicle_array.filter(
            (item) => {
              return item.status.status == 3;
            }
          ).length,
          gps_for_service_vehicles_count: this.$store.state.vehicle_array.filter(
            (item) => {
              return item.status.status == 4;
            }
          ).length,
          decommissioned_vehicles_count: this.$store.state.vehicle_array.filter(
            (item) => {
              return item.status.status == 5;
            }
          ).length,
        });
      }, 1000 * 60);
    } else {
      this.$router.push({
        path: "/",
      });

      this.$store.dispatch("clearStore");
    }
  },
  watch: {
    typeOfView: async function() {
      if (this.typeOfView === "dashboard") {
        this.$router.push({
          path: "/c/home",
        });
      }

      if (this.typeOfView === "map-view") {
        this.$router.push({
          path: "/c/dashboard/map-view",
        });
      }
    },
  },
  computed: {
    theData() {
      let theData = [];
      let aNewArray = [];

      this.$store.state.vehicle_array.forEach((item) => {
        aNewArray.push(item);
      });

      if (this.filterStatus == 1) {
        if (this.searchItem) {
          let search = new RegExp(this.searchItem, "i");

          return this.$store.state.vehicle_array.filter((item) => {
            return search.test(item.plate_no) || search.test(item.imei);
          });
        } else if (this.filterSubCompany) {
          let search = new RegExp(this.filterSubCompany, "i");

          return this.$store.state.vehicle_array.filter((item) => {
            return search.test(item.sub_company_id);
          });
        } else {
          theData = aNewArray.filter((item) => {
            return item.dispatch_count != 0;
          });

          return theData;
        }
      } else if (this.filterStatus == 2) {
        if (this.searchItem) {
          let search = new RegExp(this.searchItem, "i");

          return this.$store.state.vehicle_array.filter((item) => {
            return search.test(item.plate_no) || search.test(item.imei);
          });
        } else if (this.filterSubCompany) {
          let search = new RegExp(this.filterSubCompany, "i");

          return this.$store.state.vehicle_array.filter((item) => {
            return search.test(item.sub_company_id);
          });
        } else {
          theData = aNewArray.filter((item) => {
            return item.status.status == 2 && item.vehicle_status == 1;
          });

          return theData;
        }
      } else if (this.filterStatus == 3) {
        if (this.searchItem) {
          let search = new RegExp(this.searchItem, "i");

          return this.$store.state.vehicle_array.filter((item) => {
            return search.test(item.plate_no) || search.test(item.imei);
          });
        } else if (this.filterSubCompany) {
          let search = new RegExp(this.filterSubCompany, "i");

          return this.$store.state.vehicle_array.filter((item) => {
            return search.test(item.sub_company_id);
          });
        } else {
          theData = aNewArray.filter((item) => {
            return item.status.status == 3;
          });

          return theData;
        }
      } else if (this.filterStatus == 4) {
        if (this.searchItem) {
          let search = new RegExp(this.searchItem, "i");

          return this.$store.state.vehicle_array.filter((item) => {
            return search.test(item.plate_no) || search.test(item.imei);
          });
        } else if (this.filterSubCompany) {
          let search = new RegExp(this.filterSubCompany, "i");

          return this.$store.state.vehicle_array.filter((item) => {
            return search.test(item.sub_company_id);
          });
        } else {
          theData = aNewArray.filter((item) => {
            return item.status.status == 4;
          });

          return theData;
        }
      } else if (this.filterStatus == 5) {
        if (this.searchItem) {
          let search = new RegExp(this.searchItem, "i");

          return this.$store.state.vehicle_array.filter((item) => {
            return search.test(item.plate_no) || search.test(item.imei);
          });
        } else if (this.filterSubCompany) {
          let search = new RegExp(this.filterSubCompany, "i");

          return this.$store.state.vehicle_array.filter((item) => {
            return search.test(item.sub_company_id);
          });
        } else {
          theData = aNewArray.filter((item) => {
            return item.status.status == 5;
          });

          return theData;
        }
      } else if (this.filterStatus == 6) {
        if (this.searchItem) {
          let search = new RegExp(this.searchItem, "i");

          return this.$store.state.vehicle_array.filter((item) => {
            return search.test(item.plate_no) || search.test(item.imei);
          });
        } else if (this.filterSubCompany) {
          let search = new RegExp(this.filterSubCompany, "i");

          return this.$store.state.vehicle_array.filter((item) => {
            return search.test(item.sub_company_id);
          });
        } else {
          theData = aNewArray.filter((item) => {
            return item.status.status == 6 && item.vehicle_status == 1;
          });

          return theData;
        }
      } else if (this.filterStatus == 8) {
        if (this.searchItem) {
          let search = new RegExp(this.searchItem, "i");

          return this.$store.state.vehicle_array.filter((item) => {
            return search.test(item.plate_no) || search.test(item.imei);
          });
        } else if (this.filterSubCompany) {
          let search = new RegExp(this.filterSubCompany, "i");

          return this.$store.state.vehicle_array.filter((item) => {
            return search.test(item.sub_company_id);
          });
        } else {
          theData = aNewArray.filter((item) => {
            return item.vehicle_status == 0;
          });

          return theData;
        }
      } else if (this.filterStatus == 9) {
        if (this.searchItem) {
          let search = new RegExp(this.searchItem, "i");

          return this.$store.state.vehicle_array.filter((item) => {
            return search.test(item.plate_no) || search.test(item.imei);
          });
        } else if (this.filterSubCompany) {
          let search = new RegExp(this.filterSubCompany, "i");

          return this.$store.state.vehicle_array.filter((item) => {
            return search.test(item.sub_company_id);
          });
        } else {
          theData = aNewArray.filter((item) => {
            if (
              (item.vehicle_status == 1 && item.status.status == 2) ||
              (item.vehicle_status == 1 && item.status.status == 6)
            ) {
              return item;
            }
          });

          return theData;
        }
      } else {
        if (this.searchItem) {
          let search = new RegExp(this.searchItem, "i");

          return this.$store.state.vehicle_array.filter((item) => {
            return search.test(item.plate_no) || search.test(item.imei);
          });
        } else if (this.filterSubCompany) {
          let search = new RegExp(this.filterSubCompany, "i");

          return this.$store.state.vehicle_array.filter((item) => {
            return search.test(item.sub_company_id);
          });
        } else {
          return this.paginate(
            this.$store.state.vehicle_array,
            10,
            this.pageNumber
          );
        }
      }
    },
  },
  methods: {
    async search(data) {
      this.searchItem = data;
    },
    async viewType() {
      this.$router.push({
        path: "/c/dashboard/map-view",
      });
    },
    backClick() {
      this.pageNumber -= 1;

      this.offset -= 10;

      this.totalItems = this.$store.state.dashboard_boxes_counts.total_vehicles_count;

      this.itemFrom = this.totalItems > 0 ? this.offset + 1 : 0;

      this.itemTo = this.offset + 10;

      if (this.totalItems <= this.itemTo) {
        this.itemTo = this.totalItems;
      }

      if (this.itemTo === this.totalItems) {
        this.disabledNext = true;
      } else {
        this.disabledNext = false;
      }

      if (this.itemFrom > 1) {
        this.disabledBack = false;
      } else {
        this.disabledBack = true;
      }
    },
    nextClick() {
      this.pageNumber += 1;

      this.offset += 10;

      this.totalItems = this.$store.state.dashboard_boxes_counts.total_vehicles_count;

      this.itemFrom = this.totalItems > 0 ? this.offset + 1 : 0;

      this.itemTo = this.offset + 10;

      if (this.totalItems <= this.itemTo) {
        this.itemTo = this.totalItems;
      }

      if (this.itemTo === this.totalItems) {
        this.disabledNext = true;
      } else {
        this.disabledNext = false;
      }

      if (this.itemFrom > 1) {
        this.disabledBack = false;
      } else {
        this.disabledBack = true;
      }
    },
    paginate(array, page_size, page_number) {
      return array.slice(
        (page_number - 1) * page_size,
        page_number * page_size
      );
    },
    async getData() {
      this.$store.dispatch("startLoading");

      this.queryParams.company_id = this.$store.state.company.id;

      try {
        // this.countObjects = (
        //   await Services.getDashboardDetails(this.queryParams)
        // ).data.data;
        await Services.getDashboard(this.queryParams);
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }

      this.$store.dispatch("endLoading");
    },
    // async getDataMap() {
    //   this.queryParams.company_id = this.$store.state.company.id;

    //   try {
    //     await Services.getDashboard(this.queryParams);
    //   } catch (error) {
    //     if (!error.response) {
    //       this.$infoBar({
    //         type: "error",
    //         text: "No network or server connection",
    //       });
    //     }
    //   }
    // },
    async getDataMap() {
      let data = null;

      this.queryParams.company_id = this.$store.state.company.id;

      try {
        const response = await Services.getDashboardApi(this.queryParams);

        data = response.data;
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }

      return data;
    },
    async getAllVehicles() {
      this.filterStatus = 0;
      this.searchItem = "";
      this.filterSubCompany = null;
      this.showPagination = true;
    },
    async getDispatchedVehicles() {
      this.filterStatus = 1;
      this.searchItem = "";
      this.filterSubCompany = null;
      this.showPagination = false;
    },
    async getRunningVehicles() {
      this.filterStatus = 2;
      this.searchItem = "";
      this.filterSubCompany = null;
      this.showPagination = false;
    },
    async getStoppedVehicles() {
      this.filterStatus = 6;
      this.searchItem = "";
      this.filterSubCompany = null;
      this.showPagination = false;
    },
    async getEngineOnVehicles() {
      this.filterStatus = 9;
      this.searchItem = "";
      this.filterSubCompany = null;
      this.showPagination = false;
    },
    async getEngineOffVehicles() {
      this.filterStatus = 8;
      this.searchItem = "";
      this.filterSubCompany = null;
      this.showPagination = false;
    },
    async getDelayVehicles() {
      this.filterStatus = 3;
      this.searchItem = "";
      this.filterSubCompany = null;
      this.showPagination = false;
    },
    async getGPSForServiceVehicles() {
      this.filterStatus = 4;
      this.searchItem = "";
      this.filterSubCompany = null;
      this.showPagination = false;
    },
    async getDecommissionedVehicles() {
      this.filterStatus = 5;
      this.searchItem = "";
      this.filterSubCompany = null;
      this.showPagination = false;
    },
    async viewCurrentLocation(plate_no, data) {
      this.viewCurrentLocationDialog = true;
      this.plateData = plate_no;

      const boundsVehicles = [];
      const vehiclesCoordinate = [];

      await setTimeout(async () => {
        this.$refs.map.initializeMap();

        let contentString = ``;

        contentString = `<span class='font-weight-bold'>${plate_no.toUpperCase()}</span><hr>
        <span class='font-weight-bold'>Location:</span> ${data.location.toUpperCase()}`;

        boundsVehicles.push({
          lat: parseFloat(data.lat),
          lng: parseFloat(data.lng),
        });

        vehiclesCoordinate.push({
          coordinates: {
            lat: parseFloat(data.lat),
            lng: parseFloat(data.lng),
          },
          content: contentString,
          id: data.index,
          image_url:
            data.status.status === 2
              ? require("../../assets/marker_icons/map/mv_eon_running.svg")
              : data.status.status === 6
              ? require("../../assets/marker_icons/map/mv_eon_stopped.svg")
              : data.status.status === 5
              ? require("../../assets/marker_icons/map/mv_deco.svg")
              : data.status.status === 4
              ? require("../../assets/marker_icons/map/mv_gps_for_service.svg")
              : data.status.status === 3
              ? require("../../assets/marker_icons/map/mv_gps_delay.svg")
              : data.status.status === 8
              ? require("../../assets/marker_icons/map/mv_eoff.svg")
              : data.status.status === 9
              ? require("../../assets/marker_icons/map/mv_eon.svg")
              : data.status.status === 7
              ? require("../../assets/marker_icons/map/mv_gps_sleep_mode.svg")
              : "",
          label_content: data.plate_no,
          in_dashboard: true,
        });

        await this.$refs.map.insertMultipleMarker(vehiclesCoordinate);
        if (boundsVehicles.length !== 0) {
          await this.$refs.map.boundsOfAllMarkers(boundsVehicles);
        }
      }, 1);
    },
    closeViewCurrentLocationDialog() {
      this.viewCurrentLocationDialog = false;
    },
    async viewCurrentAlarms(data) {
      this.viewCurrentAlertsDialog = true;

      this.currentAlertsLoading = true;

      this.currentAlertsQueryParams.imei_no = data.imei;
      this.currentAlertsQueryParams.company_id = this.$store.state.company.id;

      this.currentAlertsItems = (
        await Services.getCurrentAlerts(this.currentAlertsQueryParams)
      ).data.data;

      setTimeout(() => {
        this.currentAlertsLoading = false;
      }, 600);
    },
    closeViewCurrentAlertsDialog() {
      this.viewCurrentAlertsDialog = false;
    },
    async setSubCompanies() {
      const response = await Services.getSubCompanies({
        company_id: this.$store.state.company.id,
      });

      return response.data.data;
    },
  },
  async beforeDestroy() {
    // await this.$socket.emit("clearInterval", true);
    await clearInterval(this.interval);
    // this.sockets.unsubscribe("currentTransaction");
    // await Services.disconnectSocketConnection();
    this.$store.dispatch("dispatchCurrentTransaction", []);
    this.$store.dispatch("dispatchDashboardBoxesCounts", {});
  },
};
</script>

<style scoped>
.v-data-table__wrapper {
  overflow-x: auto;
}

.v-data-table__wrapper::-webkit-scrollbar {
  width: 0;
}

.gradientColor {
  background-color: #990000;
  background-image: linear-gradient(147deg, #990000 0%, #ff0000 74%);
}

.no-cursor-hover:hover {
  cursor: default;
}

.viewCurrentLocation {
  cursor: pointer;
  color: red;
  /* font-size: 10px; */
}

.viewCurrentLocation:hover {
  text-decoration: underline;
}
</style>
